import React from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";
import "../Styles/ContactUs.css";

function ContactUs() {
  return (
    <div className="contact__us">
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <Navbar />
            </div>
          </div>
        </div>
      </section>
      <section className="contact__map__section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mt-5 mb-5">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15077.76408027647!2d72.917777!3d19.1321631!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb7592745ac393e03!2sSustLabs!5e0!3m2!1sen!2sin!4v1629123351796!5m2!1sen!2sin"
                width="100%"
                height="450"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
      <section className="contact__details mt-5 mb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <h4>Registered Address</h4>
              <p className="mt-4 br__right">
                SustLabs, CM-10, SINE, CSRE Building, IIT Bombay, Powai,
                Mumbai-400076, Maharashtra, India
              </p>
            </div>
            <div className="col-md-3">
              <h4>Office Address</h4>
              <p className="mt-4 br__right">
                Sustainable Reference Analytics Pvt. Ltd.
                6033, SINE, Rahul Bajaj Technology Innovation Centre,
                IIT Bombay, Powai
                Mumbai - 400076, Maharashtra, India
              </p>
            </div>
            <div className="col-md-3">
              <h4>Email </h4>
              <p className="mt-4 br__right">
                Queries: support@sustlabs.com
                <br></br>
                Job: careers@sustlabs.com
              </p>
            </div>
            <div className="col-md-3">
              <h4>Phone number </h4>
              <p className="mt-4">
                Landline: +91-22-2085-0360
                <br></br>
                Mobile: +91-7738-880-011
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <Footer />
      </section>
    </div>
  );
}

export default ContactUs;
