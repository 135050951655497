import React from "react";
import "../Styles/Product.css";
import Footer from "./Footer";
import Navbar from "./Navbar";
import productMap from "../Assets/product/map.gif";
import { Button } from "@material-ui/core";
import ohm1 from "../Assets/product/ohm1.png";
import ohm2 from "../Assets/product/ohm2.png";
import ohm3 from "../Assets/product/ohm3.png";

import techVideo from "../Assets/technology/techVideo.mp4";
import NavbarLight from "./NavbarLight";
import Map from "./Map";

function Product() {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <NavbarLight />
        </div>
      </div>
      <section className="product__main">
        <div className="row product__section pb-5">
          <div className="col-md-5 col-12 offset-md-1 product__section__firstCol">
            <h1 className="font-weight-bold text-light mb-0">
              Changing the way <br />
            </h1>
            <h4 className="text-light">India is consuming Electricity. </h4>
            <Button className="mt-5 mb-md-5">Now in 70+ cities</Button>
          </div>
          <div className="col-md-6 product__section__firstCol">
            <img
              src={productMap}
              alt="techMap"
              className="img-fluid d-block m-auto"
              width="500px"
            />
            {/* <Map /> */}
          </div>
        </div>
      </section>
      <section className="meet__ohm__section">
        <div className="row meet__ohm mt-5">
          <div className="col-12">
            <h1 className="text-center">Meet Ohm Assistant </h1>
            <p className="text-center">It makes your home smarter. </p>
          </div>
          <div className="col-12 mt-5">
            <div className="container-fluid gray__bg">
              <div className="row d-flex align-items-center mt-5 pt-md-5 pb-md-5">
                <div className="col-md-4 offset-md-1 mt-md-0 mt-5 pt-md-5">
                  <h2>Ohm for every Home </h2>
                  <p className="mt-5 pb-md-5">
                    Ohm Assistant is an electricity activity tracker designed to
                    help you become energy wiser and ultimately making your
                    homes smarter. Our real-time energy monitoring bot is
                    designed for residential electricity users.
                  </p>
                  <a
                    href="https://ohmassistant.com/"
                    style={{ textDecoration: "none" }}
                    target="_blank"
                  >
                    <a
                      href="https://ohmassistant.com/"
                      style={{ textDecoration: "none" }}
                      target="_blank"
                    >
                      <button className="btn know__btn mt-5">Know More</button>
                    </a>
                  </a>
                </div>
                <div className="col-md-6">
                  <img
                    src={ohm1}
                    alt=""
                    className="img-fluid d-block m-auto"
                    width="350px"
                  />
                </div>
              </div>
              <div className="row mt-5 pt-md-5 d-flex pb-md-5">
                <div className="col-md-6 mt-5">
                  <img
                    src={ohm2}
                    alt=""
                    className="img-fluid d-block m-auto"
                    width="350px"
                  />
                </div>
                <div className="col-md-4 mt-md-0 mt-5">
                  <h2 className="mt-md-5">Omega for Business </h2>
                  <p className="mt-5 pb-md-5">
                    Omega is a real-time auditing tool for the top management to
                    track the electricity consumption, get alerts and
                    notifications and generate consumption reports.
                  </p>
                  <a
                    href="https://ohmassistant.com/"
                    style={{ textDecoration: "none" }}
                    target="_blank"
                  >
                    <button className="btn know__btn mt-5">Know More</button>
                  </a>
                </div>
              </div>
              <div className="row pt-md-5 d-flex align-items-center mt-5 pb-md-5">
                <div className="col-md-4 offset-md-1 mt-md-0 mt-5">
                  <h2>Adopt the OHM stack </h2>
                  <p className="mt-5 pb-md-5">
                    AiA is a licensing arrangement with global brands such as
                    metering, switch-gears, telecom companies and utilities. Our
                    clients are offering energy management solutions to its
                    customers under their brand which is assisted by Ohm.
                  </p>
                  <a
                    href="https://ohmassistant.com/"
                    style={{ textDecoration: "none" }}
                    target="_blank"
                  >
                    <button className="btn know__btn mt-5">Know More</button>
                  </a>
                </div>
                <div className="col-md-6">
                  <img
                    src={ohm3}
                    alt=""
                    className="img-fluid d-block m-auto"
                    width="350px"
                  />
                </div>
              </div>
              <div className="row ohm__smartHome pt-md-5 mt-5 pb-md-5">
                <div className="col-md-12 mt-5">
                  <video autoPlay loop muted="true" className="mt-5">
                    <source src={techVideo} type="video/mp4" />
                  </video>
                  <h2 className="font-weight-bold text-center mt-5 mb-5">
                    A wiser you and a <span>smarter home.</span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="row">
        <Footer />
      </div>
    </div>
  );
}

export default Product;
