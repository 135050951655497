import React from "react";
import Navbar from "./Navbar";
import "../Styles/TermsAndConditions.css";
import Footer from "./Footer";

function TermsAndConditions() {
  return (
    <div className="terms-and-condition">
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <Navbar />
            </div>
          </div>
        </div>
      </section>
      '
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-12 mt-md-5 mt-2">
              <h1 className="text-center mt-5">Terms & Conditions</h1>
              <p className="text-center">
                The terms "We" / "Us" / "Our"/”Company” individually and
                collectively refer to SustLabs.com and the terms
                <br></br>"Visitor” ”User” refers to the users
              </p>
            </div>
            <div className="col-md-10 offset-md-1 b__gray mt-5">
              <h4 className="ml-md-5 mt-2">Terms of Purchase </h4>
            </div>
            <div className="col-md-10 offset-md-1 mt-5">
              <p>
                (1) The prices shown are inclusive of Taxes (18% GST included).
              </p>
              <p>
                (2) Payment Terms: Online payment via Net-banking, Debit/ Credit
                Card/ UPI.
              </p>
              <p>
                (3) Delivery Period: 4-6 working days after completion of the
                order.
              </p>
              <p>(4) App is free for a year</p>
              <p>(5) Product Warranty - 1 year.</p>
              <p className="mt-5">
                This page states the Terms and Conditions under which you
                (Visitor) may visit this website (“Website”). Please read this
                page carefully. If you do not accept the Terms and Conditions
                stated here, we would request you to exit this site. The
                business, any of its business divisions and / or its
                subsidiaries, associate companies or subsidiaries to
                subsidiaries or such other investment companies (in India or
                abroad) reserve their respective rights to revise these Terms
                and Conditions at any time by updating this posting. You should
                visit this page periodically to re-appraise yourself o of the
                Terms and Conditions, because they are binding on all users of
                this Website.
              </p>
            </div>

            <div className="col-md-10 offset-md-1 b__gray mt-5">
              <h4 className="ml-md-5 mt-2">Use of content </h4>
            </div>
            <div className="col-md-10 offset-md-1 mt-5">
              <p>
                All logos, brands, marks headings, labels, names, signatures,
                numerals, shapes or any combinations thereof, appearing in this
                site, except as otherwise noted, are properties either owned, or
                used under license, by the business and / or its associate
                entities who feature on this Website. The use of these
                properties or any other content on this site, except as provided
                in these terms and conditions or in the site content, is
                strictly prohibited.
              </p>
              <p>
                You may not sell or modify the content of this Website or
                reproduce, display, publicly perform, distribute, or otherwise
                use the materials in any way for any public or commercial
                purpose without the respective organization’s or entity’s
                written permission.
              </p>
              <p>
                <strong>(A) Secutiry Rules</strong>
              </p>
              <p>
                Visitors are prohibited from violating or attempting to violate
                the security of the Web site, including, without limitation,
              </p>
              <p>
                (1) Accessing data not intended for such user or logging into a
                server or account which the user is not authorized to access,
              </p>
              <p>
                (2) Attempting to probe, scan or test the vulnerability of a
                system or network or to breach security or authentication
                measures without proper authorization,
              </p>
              <p>
                (3) Attempting to interfere with service to any user, host or
                network, including, without limitation, via means of submitting
                a virus or "Trojan horse" to the Website, overloading,
                "flooding", "mail bombing" or "crashing", or
              </p>
              <p>
                (4) Sending unsolicited electronic mail, including promotions
                and/or advertising of products or services. Violations of system
                or network security may result in civil or criminal liability.
                The business and/or its associate entities will have the right
                to investigate occurrences that they suspect as involving such
                violations and will have the right to involve and cooperate with
                law enforcement authorities in prosecuting users who are
                involved in such violations.
              </p>
              <p>
                <strong>(B) General Rules</strong>
              </p>
              <p>
                Visitors may not use the Web Site to transmit, distribute, store
                or destroy material
              </p>
              <p>
                (1) That could constitute or encourage conduct that would be
                considered a criminal offence or violate any applicable law or
                regulation
              </p>
              <p>
                (2) In a manner that will infringe the copyright, trademark,
                trade secret or other intellectual property rights of others or
                violate the privacy or publicity of other personal rights of
                others, or
              </p>
              <p>
                (3) That is libelous, defamatory, pornographic, profane,
                obscene, threatening, abusive or hateful.
              </p>
            </div>

            <div className="col-md-10 offset-md-1 b__gray mt-5">
              <h4 className="ml-md-5 mt-2">Indemnity </h4>
            </div>
            <div className="col-md-10 offset-md-1 mt-5">
              <p>
                The User unilateral agrees to indemnify and hold harmless,
                without objection, the Company, its officers, directors,
                employees and agents from and against any claims, actions and/or
                demands and/or liabilities and/or losses and/or damages
                whatsoever arising from or resulting from their use of
                https://www.sustlabs.com/ or their breach of the terms .
              </p>
            </div>

            <div className="col-md-10 offset-md-1 b__gray mt-5">
              <h4 className="ml-md-5 mt-2">Liability </h4>
            </div>
            <div className="col-md-10 offset-md-1 mt-5">
              <p>
                User agrees that neither Company nor its group companies,
                directors, officers or employee shall be liable for any direct
                or/and indirect or/and incidental or/and special or/and
                consequential or/and exemplary damages, resulting from the use
                or/and the inability to use the service or/and for cost of
                procurement of substitute goods or/and services or resulting
                from any goods or/and data or/and information or/and services
                purchased or/and obtained or/and messages received or/and
                transactions entered into through or/and from the service or/and
                resulting from unauthorized access to or/and alteration of
                user's transmissions or/and data or/and arising from any other
                matter relating to the service, including but not limited to,
                damages for loss of profits or/and use or/and data or other
                intangible, even if Company has been advised of the possibility
                of such damages.
              </p>
              <p>
                Ohm is connected to dangerous voltages. Improper use or
                installation can be dangerous or even fatal. Please make sure
                that the installation is done by a qualified professional, and
                that you follow these guidelines for installation and use: (i)
                Have the installation done by a qualified electrician, according
                to local electrical codes. (ii) Do not try to open the Ohm
                monitor, touch any internal parts, or try to repair it without
                first contacting Ohm support. (iii) If you believe the monitor
                or cables may have been damaged, do not try to use them. Please
                contact Ohm support. 4) Use the Ohm monitor only in India, and
                only with a 50Hz 240V supply system. 5) Install the Ohm monitor
                where it will not be exposed to direct sunlight or extremely low
                or high temperatures. No exposure to water.
              </p>
              <p>
                User further agrees that Company shall not be liable for any
                damages arising from interruption, suspension or termination of
                service, including but not limited to direct or/and indirect
                or/and incidental or/and special consequential or/and exemplary
                damages, whether such interruption or/and suspension or/and
                termination was justified or not, negligent or intentional,
                inadvertent or advertent. User agrees that Company shall not be
                responsible or liable to user, or anyone, for the statements or
                conduct of any third party of the service. In sum, in no event
                shall the Company's total liability to the User for all damages
                or/and losses or/and causes of action exceed the amount paid by
                the User to Company, if any, that is related to the cause of
                action.
              </p>
            </div>

            <div className="col-md-10 offset-md-1 b__gray mt-5">
              <h4 className="ml-md-5 mt-2">
                Account Creation, Subscriptions, Renewals and Terminations
              </h4>
            </div>
            <div className="col-md-10 offset-md-1 mt-5">
              <p>
                In order to use the Services, you must register and create an
                account. Certain users are or may be designated as
                administrative users (“Admin Users”). Admin Users can create
                additional authorized user accounts and assign certain
                permissions to such additional accounts. Depending on the level
                of permissions assigned to your user account, you may not have
                access to or be able to view or use all of the functions or
                features of the Services.
              </p>
              <p>
                You may need to provide certain registration details or other
                information on behalf of yourself or other users to create an
                account and to otherwise access and use the Services. It is a
                condition of your access and use of the Services that all the
                information you provide is correct, current, and complete.
              </p>
              <p>
                The Services are available under a basic (free) account or a
                premium (fee-based) monthly or annual subscription plan as
                offered on the plan options page. Users may upgrade or downgrade
                between a basic account and a premium subscription plan. Your
                agreement for purchasing a paid subscription plan becomes
                binding upon clicking “I Accept” for a premium plan. Each
                premium subscription term will automatically renew for the
                agreed upon term (e.g. 1 month or 12 months), unless you cancel
                the subscription before the current term expires. The full
                amount for each renewal term will be charged on the first day of
                the renewal.
              </p>
              <p>
                If You terminate a free account or a premium subscription plan,
                your account will become unusable and your access to the
                Services will be terminated. Users are not entitled to any
                refund of subscription fees. We are under no further obligations
                to you upon your termination of the subscription. SustLabs
                reserves the right to cancel free accounts or paid subscriptions
                at any time for any reason.
              </p>
            </div>

            <div className="col-md-10 offset-md-1 b__gray mt-5">
              <h4 className="ml-md-5 mt-2">Payment Processing</h4>
            </div>
            <div className="col-md-10 offset-md-1 mt-5">
              <p>
                In order to purchase a subscription, you will be required to
                provide credit or payment card information, your billing address
                and related information. You represent and warrant that you have
                the right to use any credit or payment card or other payment
                mechanism that you submit in connection with the purchase of a
                subscription and that you have all authority necessary to
                purchase a subscription using the credit card or payment
                mechanism that you submit. By submitting such information, you
                grant us the right to provide such information to third parties
                for the purposes of facilitating your purchase. You acknowledge
                and agree that we are not responsible for how any third party
                credit card or other payment method processor transmits, stores,
                uses or shares your information.
              </p>
            </div>

            <div className="col-md-10 offset-md-1 b__gray mt-5">
              <h4 className="ml-md-5 mt-2">
                Disclamer of Consequental damages{" "}
              </h4>
            </div>
            <div className="col-md-10 offset-md-1 mt-5">
              <p>
                <strong>(A) CONSEQUENTIAL DAMAGES</strong>
              </p>
              <p>
                In no event shall Company or any parties, organizations or
                entities associated with the corporate brand name us or
                otherwise, mentioned at this Website be liable for any damages
                whatsoever (including, without limitations, incidental and
                consequential damages, lost profits, or damage to computer
                hardware or loss of data information or business interruption)
                resulting from the use or inability to use the Website and the
                Website material, whether based on warranty, contract, tort, or
                any other legal theory, and whether or not, such organization or
                entities were advised of the possibility of such damages.
              </p>
              <p>
                <strong>(B) HARDWARE/FIRMWARE</strong>
              </p>
              <p>
                Ohm assistant cannot be held liable for accessory damage
                associated with the installation and use of the Hardware (LEDs,
                connectors, wires, etc.).The chips cannot be removed from the
                OHM device unit and not be used in other IoT services. OHM bot
                performance is depending upon the WIFI infrastructure available
                at your place. So performance may reduce if there is no stable
                WIFI connection available. For proper functionality of the bot,
                it is advisable to whitelist required IP addresses and ports of
                communications. Ohm Assistant is not capable of overriding the
                router's connection limit. Inorder to reconnect bot with WIFI,
                manual intervention may be required. Bot can operate on WiFi
                bands 802.11 b/g/n - 2.4 GHz only. Additional charges would be
                applicable for reconfiguration/ reinstallation incase of
                relocation. Multiple installation attempts for the bot would
                result in erroneous/corrupted data.
              </p>
              <p>
                <strong>(C) APPLIANCE DETECTION</strong>
              </p>
              <p>
                The detection that is bot does are the predictions and not the
                actual consumption of the appliances.As of now, we are able to
                detect only heavy appliances in the house i.e., Air
                conditioners, geysers, washing machines, fridges and heating
                devices (consisting of iron, kettle, oven, microwave ). We are
                operating at an average detection accuracy of 85%. The accuracy
                may affect depending on the size of the house. Appliance
                segregation is provided at an overall level and not on
                individual level or phase wise. For example, We would be able to
                predict the consumption of AC’s altogether and not how much each
                AC is consuming. Depending on the size of house and number of
                appliances, some appliances may not get classified correctly.We
                do not explicitly mention leakage or theft causing
                appliances.Train your bot may or may not improve the appliance
                detection accuracy.Wifi availability affects the appliance
                detection accuracy.
              </p>
              <p>
                ohm assistant services maybe down in case of scheduled/
                unscheduled maintenance for 12hrs, users may be informed prior
                incase maintenance exceeds 12hrs.Modifying network settings like
                using vpn, battery optimizers or firewalls etc. may result in
                service interruption
              </p>
              <p>
                If you have any questions or to access personal information we
                may have of yours, please contact us at support@sustlabs.com
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <Footer />
      </section>
    </div>
  );
}

export default TermsAndConditions;
