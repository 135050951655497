import { useEffect } from "react";
import { Route, Router, Switch, useHistory } from "react-router-dom";
import About from "../Pages/About";
import ContactUs from "../Pages/ContactUs";
import Faq from "../Pages/Faq";
import Home from "../Pages/Home";
import Partner from "../Pages/Partner";
import PrivacyPolicy from "../Pages/PrivacyPolicy";
import Product from "../Pages/Product";
import SalesandRefund from "../Pages/SalesandRefund";
import Salesforce from "../Pages/Salesforce";
import Technology from "../Pages/Technology";
import TermsAndConditions from "../Pages/TermsAndConditions";
import Compliance from "../Pages/compliance";

const Routes = () => {
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/" component={Home}></Route>
        <Route exact path="/product" component={Product}></Route>
        <Route exact path="/privacy-policy" component={PrivacyPolicy}></Route>
        <Route exact path="/technology" component={Technology}></Route>
        <Route exact path="/partner" component={Partner}></Route>
        <Route exact path="/about" component={About}></Route>
        <Route
          exact
          path="/sales-and-refund"
          component={SalesandRefund}
        ></Route>

        <Route
          exact
          path="/term-and-condition"
          component={TermsAndConditions}
        ></Route>
        <Route exact path="/contact-us" component={ContactUs}></Route>
        <Route exact path="/compliance" component={Compliance}></Route>
        <Route exact path="/faq" component={Faq}></Route>
        <Route exact path="/salesforce" component={Salesforce}></Route>

        {/*
      
     
        <Route path="/support" component={Support}></Route>
       
        <Route
          path="/cancellation-and-return-policy"
          component={CancellationAndReturnPolicy}
        ></Route>
        
        */}
      </Switch>
    </Router>
  );
};

export default Routes;
