import React from "react";
import "../Styles/About.css";
import Footer from "./Footer";
import Navbar from "./Navbar";
import akku from "../Assets/about/akku.png";
import amrutha from "../Assets/about/amrutha.png";
import ashish from "../Assets/about/ashish.png";
import deepika from "../Assets/about/deepika.png";
import f1 from "../Assets/about/f1.png";
import f2 from "../Assets/about/f2.png";
import f3 from "../Assets/about/f3.png";
import f4 from "../Assets/about/f4.png";
import gulam from "../Assets/about/gulam.png";
import ishani from "../Assets/about/ishani.png";
import jyoti from "../Assets/about/jyoti.png";
import kaushik from "../Assets/about/kaushik.png";
import kl from "../Assets/about/kl.png";
import meet from "../Assets/about/meet.png";
import navil from "../Assets/about/navil.png";
import pp from "../Assets/about/pp.png";
import rohan from "../Assets/about/rohan.png";
import ruhi from "../Assets/about/ruhi.png";
import sb from "../Assets/about/sb.png";
import shikha from "../Assets/about/shikha.png";
import suchitra from "../Assets/about/suchitra.png";
import NavbarLight from "./NavbarLight";

function About() {
  return (
    <div className="about">
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <NavbarLight />
            </div>
          </div>
        </div>
      </section>
      <section className="about__main">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mt-5 pt-5">
              <h1 className="text-light mt-5 pt-5">Diverse experiences, </h1>
              <h5 className="text-light">Shared pursuit </h5>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-5 about__sub__text">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-md-8">
              <p>
                The team behind Ohm Assistant is more than a family. SustLabs is
                a way of life for us. While you don’t get to see the team
                sleeping on the table and the couches, here’s an opportunity to
                experience something from the window.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="about__team mt-5 pt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="mt-md-5 text-center">Meet the Team </h1>
            </div>
          </div>
          <div className="row d-flex justify-content-evenly align-items-center mt-5">
            <div className="col-md-2 col-6 mt-5">
              <img src={amrutha} alt="" className="img-fluid" />
              <p className="mb-0 mt-2" style={{ fontWeight: "bold" }}>
                Amruta
              </p>
              <p>Big Data</p>
            </div>
            <div className="col-md-2 col-6 mt-5">
              <img src={akku} alt="" className="img-fluid" />
              <p className="mb-0 mt-2" style={{ fontWeight: "bold" }}>
                Akanksha
              </p>
              <p>Sales</p>
            </div>
            <div className="col-md-2 col-6 mt-5">
              <img src={ashish} alt="" className="img-fluid" />
              <p className="mb-0 mt-2" style={{ fontWeight: "bold" }}>
                Ashish
              </p>
              <p>Tech</p>
            </div>
            <div className="col-md-2 col-6 mt-5">
              <img src={deepika} alt="" className="img-fluid" />
              <p className="mb-0 mt-2" style={{ fontWeight: "bold" }}>
                Deepika
              </p>
              <p>Sales</p>
            </div>
          </div>
          <div className="row d-flex justify-content-evenly align-items-center">
            <div className="col-md-2 col-6 mt-5">
              <img src={gulam} alt="" className="img-fluid" />
              <p className="mb-0 mt-2" style={{ fontWeight: "bold" }}>
                Gulam
              </p>
              <p>Android Developer</p>
            </div>
            <div className="col-md-2 col-6 mt-5">
              <img src={ishani} alt="" className="img-fluid" />
              <p className="mb-0 mt-2" style={{ fontWeight: "bold" }}>
                Ishani
              </p>
              <p>Business Development</p>
            </div>
            <div className="col-md-2 col-6 mt-5">
              <img src={jyoti} alt="" className="img-fluid" />
              <p className="mb-0 mt-2" style={{ fontWeight: "bold" }}>
                Jyoti
              </p>
              <p>Sales</p>
            </div>
            <div className="col-md-2 col-6 mt-5">
              <img src={kaushik} alt="" className="img-fluid" />
              <p className="mb-0 mt-2" style={{ fontWeight: "bold" }}>
                Kaushik
              </p>
              <p>Co-Founder</p>
            </div>
          </div>
          <div className="row d-flex justify-content-evenly align-items-center">
            <div className="col-md-2 col-6 mt-5">
              <img src={kl} alt="" className="img-fluid" />
              <p className="mb-0 mt-2" style={{ fontWeight: "bold" }}>
                Khushboo
              </p>
              <p>Product</p>
            </div>
            <div className="col-md-2 col-6 mt-5">
              <img src={meet} alt="" className="img-fluid" />
              <p className="mb-0 mt-2" style={{ fontWeight: "bold" }}>
                Meet
              </p>
              <p>Machine Learning</p>
            </div>
            <div className="col-md-2 col-6 mt-5">
              <img src={navil} alt="" className="img-fluid" />
              <p className="mb-0 mt-2" style={{ fontWeight: "bold" }}>
                Navil
              </p>
              <p>Sales</p>
            </div>
            <div className="col-md-2 col-6 mt-5">
              <img src={pp} alt="" className="img-fluid" />
              <p className="mb-0 mt-2" style={{ fontWeight: "bold" }}>
                Prasad
              </p>
              <p>Operation</p>
            </div>
          </div>
          <div className="row d-flex justify-content-evenly align-items-center">
            <div className="col-md-2 col-6 mt-5">
              <img src={rohan} alt="" className="img-fluid" />
              <p className="mb-0 mt-2" style={{ fontWeight: "bold" }}>
                Rohan
              </p>
              <p>Co-Founder</p>
            </div>
            <div className="col-md-2 col-6 mt-5">
              <img src={ruhi} alt="" className="img-fluid" />
              <p className="mb-0 mt-2" style={{ fontWeight: "bold" }}>
                Ruhi
              </p>
              <p>Sales</p>
            </div>
            <div className="col-md-2 col-6 mt-5">
              <img src={shikha} alt="" className="img-fluid" />
              <p className="mb-0 mt-2" style={{ fontWeight: "bold" }}>
                Shikha
              </p>
              <p>Design</p>
            </div>
            <div className="col-md-2 col-6 mt-5">
              <img src={sb} alt="" className="img-fluid" />
              <p className="mb-0 mt-2" style={{ fontWeight: "bold" }}>
                Soumya
              </p>
              <p>Co-Founder</p>
            </div>
          </div>

          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-md-8 d-flex justify-content-center align-items-center">
              <h1>This is how we Roll </h1>
            </div>
            <div className="col-md-2 col-6">
              <img src={suchitra} alt="" className="img-fluid" />
              <p className="mb-0 mt-2" style={{ fontWeight: "bold" }}>
                Suchitra
              </p>
              <p>Application Developer</p>
            </div>
          </div>
        </div>
      </section>
      <section className="about__family mt-5 pt-5 pb-5">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-10">
                  <img src={f4} alt="" className="img-fluid" />
                </div>
                {/* <div className="col-md-5">
                  <img src={f2} alt="" className="img-fluid" />
                </div> */}
              </div>
              <div className="row mt-3">
                <div className="col-md-10">
                  <img src={f3} alt="" className="img-fluid" />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <h5>Join the</h5>
              <h3>SustLabs family </h3>
              <p className="mt-5">
 Our breed is going to solve the problem related to
                sustainability with energy and water-efficient technologies. We
                position ourselves as the company that would be enabling the
                ecosystem to do so. And we are determined to not let go of this
                opportunity.
              </p>
              <p className="mt-5">
                Want to work with us? Write to us at careers@sustlabs.com
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <Footer />
      </section>
    </div>
  );
}

export default About;
