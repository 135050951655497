import React from "react";
import { Link } from "react-router-dom";
import "../Styles/Navbar.css";
import { useLocation } from "react-router-dom";
// import ohm from "../Assets/Home/ohm.png";
// import sustlabs from "../Assets/Home/sustlabs.png";
import comb from "../Assets/combineLogo.png";

function Navbar() {
  const location = useLocation();

  return (
    <div className="container-fluid p-0 navbar__dark">
      <div className="row">
        <div className="col-md-12 p-0">
          <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <Link to="/" className="navbar-brand">
                    <img
                      src={comb}
                      alt="sustlabs-logo"
                      className="img-fluid"
                      width="200px"
                    />
                  </Link>
                  <a
                    href="http://ohmassistant.com/"
                    target="_blank"
                    className="navbar-brand"
                  >
                    {/* <img src={ohm} alt="sustlabs-logo" className="img-fluid" /> */}
                  </a>
                </div>
              </div>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse"></div>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <Link
                      className={
                        location.pathname === "/"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/"
                    >
                      Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={
                        location.pathname === "/product"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/product"
                    >
                      Product
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={
                        location.pathname === "/technology"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/technology"
                    >
                      Technology
                    </Link>
                  </li>
                  {/* <li className="nav-item">
                    <Link className="nav-link" to="/community">
                      COMMUNITY
                    </Link>
                  </li> */}
                  <li className="nav-item">
                    <Link
                      className={
                        location.pathname === "/partner"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/partner"
                    >
                      Partner
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={
                        location.pathname === "/support"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/about"
                    >
                      About
                    </Link>
                  </li>
                </ul>

                <ul className="navbar-nav my-2 my-lg-0">
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="https://shop.ohmassistant.com/"
                      target="_blank"
                    >
                      Buy
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="https://b2b-sustlabs.firebaseapp.com/#/login"
                      target="_blank"
                    >
                      Account
                    </a>
                  </li>
                </ul>
                {/* <ul className="navbar-nav my-2 my-lg-0 ml-3">
                  <li className="nav-link">
                    <nav role="navigation">
                      <div id="menuToggle">
                        <input type="checkbox" />
                        <span className="span1"></span>
                        <span className="span2"></span>
                        <span className="span3"></span>
                        <ul id="menu">
                          <li className="nav-item">
                            <Link className="nav-link" to="/">
                              HOME
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link className="nav-link" to="/product">
                              PRODUCT
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link className="nav-link" to="/technology">
                              TECHNOLOGY
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link className="nav-link" to="/partner">
                              PARTNER
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link className="nav-link" to="/support">
                              SUPPORT
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </nav>
                  </li>
                </ul> */}
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
