import React from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";
import "../Styles/Compliance.css";

function Compliance() {
    return (
        <div>
            <section>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <Navbar />
                        </div>
                    </div>
                </div>
            </section>
            <section className="compliance">
                <h4 className="title">
                    {" "}
                    Annual return e-forms of the company -{" "}
                    <a
                        className="clickHere"
                        target="_blank"
                        href="https://drive.google.com/drive/u/1/folders/1paMTZOaPzkKWHMlBLZPbOrNKGokIBVJ4"
                    >
                        {" "}
                        Click Here
                    </a>
                </h4>
            </section>
            <section>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <Footer />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Compliance;
