import { Button } from "@material-ui/core";
import React, { useRef } from "react";
import "../Styles/Home.css";
import Navbar from "./Navbar";
import homeVideo from "../Assets/landing/home_new.mp4";
import ciie from "../Assets/Home/ciie.png";
import cisco from "../Assets/Home/cisco.png";
import sine from "../Assets/Home/sine.png";
import social from "../Assets/Home/social.png";
import tatatrust from "../Assets/Home/tatatrust.png";
import built from "../Assets/Home/built.png";
import google from "../Assets/Home/google.png";
import nasscom from "../Assets/Home/nasscon.png";
import oracle from "../Assets/Home/oracle.png";
import mission from "../Assets/Home/mission.png";
import eco from "../Assets/Home/eco.png";
import inc from "../Assets/Home/inc.png";
import yourstory from "../Assets/Home/yourstory.png";
import vc from "../Assets/Home/vc.png";
import micro from "../Assets/Home/micro.png";
import tech from "../Assets/Home/tech.png";
// import down from "../Assets/Home/down.png";
import Footer from "./Footer";
import NavbarLight from "./NavbarLight";
import useScrollSnap from "react-use-scroll-snap";
// import $ from "jquery";

function Home() {
  const scrollRef = useRef(null);
  useScrollSnap({ ref: scrollRef, duration: 100, delay: 50 });

  return (
    <div className="container-fluid scroll-container" ref={scrollRef}>
      <div className="row scroll-area">
        <div className="col-md-12">
          <NavbarLight />
        </div>
      </div>
      <div className="row home__main__section scroll-area">
        <div className="col-md-6 col-12 offset-md-1">
          <h1 className="font-weight-bold text-light mt-md-5">
            <span>Inspiring</span> energy efficiency
          </h1>
          <h4 className="text-light">for a sustainable future.</h4>
          <Button className="mt-5 mb-md-5">
            <a
              href="https://shop.ohmassistant.com/collections/all"
              className="text-uppercase"
              target="_blank"
            >
              Get Ohm
            </a>
          </Button>
        </div>
        <div className="col-md-12 d-flex justify-content-center align-content-center">
          <a
            className="ca3-scroll-down-link ca3-scroll-down-arrow"
            data-ca3_iconfont="ETmodules"
            data-ca3_icon=""
            href="#ohm__homeAnimation"
          ></a>
        </div>
      </div>
      <div
        className="row ohm__Homeanimation scroll-area"
        id="ohm__homeAnimation"
      >
        <div className="col-md-12 p-0 d-flex justify-content-center align-items-center flex-column">
          {/* <img src={homeGif} alt="homeGif" className="img-fluid d-block m-auto" /> */}
          <video autoPlay loop width="100%" muted="true">
            <source src={homeVideo} type="video/mp4" />
          </video>
          {/* <p>
            Integrating homes in the smart and sustainable world through{" "}
            <br></br>
            informed energy consumption choices.
          </p> */}
        </div>
      </div>
      <div className="row built__section mt-5 scroll-area">
        <div className="col-12 mt-5">
          <h1 className="text-dark text-center font-weight-bold">
            Built for every household
          </h1>
        </div>
        <div className="col-12 d-flex justify-content-center align-items-center">
          <span>Bungalows</span>
          <span>|</span>
          <span>Flats</span>
          <span>|</span>
          <span>Small Offices</span>
          <span>|</span>
          <span>Tenements</span>
        </div>
        <div className="col-12">
          <img src={built} alt="" className="img-fluid" />
        </div>
      </div>
      {/* <div className="container mt-5 mb-5 pb-5"> */}
      <div className="row built__trust__section scroll-area">
        <div className="col-12 mt-5">
          <h1 className="text-center font-weight-bold">
            We’ve built trust through the journey
          </h1>
          <p className="text-center">
            We find in them a testament of faith in our vision
          </p>
        </div>
        <div className="col-md-10 offset-md-1 mt-5">
          <div className="row d-flex justify-content-center align-items-center mt-5">
            <div className="col-md-3 col-6">
              <img src={ciie} alt="" className="img-fluid" />
            </div>
            <div className="col-md-3 col-6">
              <img src={cisco} alt="" className="img-fluid m-auto" />
            </div>
            <div className="col-md-3 mt-md-0 mt-3 col-6">
              <img src={social} alt="" className="img-fluid m-auto" />
            </div>

            <div className="col-md-3 col-6 mt-md-0 mt-3">
              <img src={sine} alt="" className="img-fluid ml-auto" />
            </div>
          </div>
          <div className="row mt-md-5 mt-5 d-flex justify-content-center align-items-center">
            <div className="col-md-3 col-6 mt-md-0 mt-3">
              <img src={nasscom} alt="" className="img-fluid" />
            </div>
            <div className="col-md-3 col-6 mt-md-0 mt-3">
              <img src={google} alt="" className="img-fluid" />
            </div>
            <div className="col-md-3 col-6 mt-md-0 mt-3">
              <img src={micro} alt="" className="img-fluid" />
            </div>
            <div className="col-md-3 col-6 mt-md-0 mt-5">
              <img src={oracle} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
      <div className="row making__noise mt-5 mb-5 pb-5 scroll-area">
        <div className="col-12 mt-5">
          <h1 className="text-center font-weight-bold">
            We’re making some noise
          </h1>
        </div>
        <div className="col-md-10 offset-md-1 mt-5">
          <div className="row d-flex justify-content-center align-items-center mt-5">
            <div className="col-md-4 col-6 mt-md-0 mt-3">
              <img src={mission} alt="" className="img-fluid" />
            </div>
            <div className="col-md-4 col-6 mt-md-0 mt-3">
              <img src={eco} alt="" className="img-fluid" />
            </div>
            <div className="col-md-4 col-6 mt-md-0 mt-3">
              <img src={tech} alt="" className="img-fluid" />
            </div>
          </div>
          <div className="row mt-md-5 mt-3 d-flex justify-content-center align-items-center">
            <div className="col-md-4 col-6  mt-md-0 mt-3">
              <img src={inc} alt="" className="img-fluid" />
            </div>
            <div className="col-md-4 col-6 mt-md-0 mt-3">
              <img src={yourstory} alt="" className="img-fluid" />
            </div>
            <div className="col-md-4 col-6 mt-md-0 mt-3">
              <img src={vc} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
      <div className="row mt-5 scroll-area">
        <Footer />
      </div>
    </div>
  );
}

export default Home;
