import React from "react";
import "../Styles/Partner.css";
import Navbar from "./Navbar";
import adda from "../Assets/partners/adda.svg";
import cisco from "../Assets/partners/cisco.svg";
import ddl from "../Assets/partners/ddl.svg";
import elem from "../Assets/partners/elem.svg";
import giz from "../Assets/partners/giz.svg";
import hood from "../Assets/partners/hood.svg";
import house from "../Assets/partners/house.svg";
import lt from "../Assets/partners/lt.svg";
import mygate from "../Assets/partners/mygate.svg";
import sch from "../Assets/partners/sch.svg";
import sezzle from "../Assets/partners/sezzle.svg";
import tata from "../Assets/partners/tata.svg";
import Footer from "./Footer";

function Partner() {
  return (
    <div className="partner">
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <Navbar />
            </div>
          </div>
        </div>
      </section>
      <section className="partner__logo mt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="text-center">With our partners,</h1>
              <h4 className="text-center">the possibilities are infinte. </h4>
              <p className="mt-5 text-center">
                In the pursuit to showcase Ohm Assistant to the world, we've
                partnered with some
              </p>
              <p className="text-center">
                path-breaking companies who make us reach our most important
                stakeholder: you.
              </p>
            </div>
          </div>
          <div className="row mt-5 d-flex justify-content-center align-items-center">
            <div className="col-md-3 col-6">
              <img src={cisco} alt="" className="img-fluid" />
            </div>
            <div className="col-md-3 col-6">
              <img src={mygate} alt="" className="img-fluid" />
            </div>
            <div className="col-md-3 col-6 mt-md-0 mt-3">
              <img src={sezzle} alt="" className="img-fluid" />
            </div>
          </div>
          <div className="row mt-md-5 mt-3 d-flex justify-content-center align-items-center">
            <div className="col-md-3 col-6">
              <img src={sch} alt="" className="img-fluid" />
            </div>
            <div className="col-md-3 col-6">
              <img src={elem} alt="" className="img-fluid" />
            </div>
            <div className="col-md-3 col-6 mt-md-0 mt-3">
              <img src={hood} alt="" className="img-fluid" />
            </div>
          </div>
          <div className="row mt-md-5 mt-3 d-flex justify-content-center align-items-center">
            <div className="col-md-3 col-6">
              <img src={house} alt="" className="img-fluid" />
            </div>
            <div className="col-md-3 col-6">
              <img src={tata} alt="" className="img-fluid" />
            </div>
            <div className="col-md-3 col-6 mt-md-0 mt-3">
              <img src={ddl} alt="" className="img-fluid" />
            </div>
          </div>
          <div className="row mt-md-5 mt-3 d-flex justify-content-center align-items-center">
            <div className="col-md-3 col-6">
              <img src={lt} alt="" className="img-fluid" />
            </div>
            <div className="col-md-3 col-6">
              <img src={adda} alt="" className="img-fluid" />
            </div>
            <div className="col-md-3 col-6 mt-md-0 mt-3">
              <img src={giz} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </section>

      <section className="partner__form pb-5">
        <div className="container">
          <div className="row mt-5">
            <h1 className="text-center mt-5">Partner with us </h1>
            <h4 className="text-center">
              Looking to partner with us? We’re nice people to do business with{" "}
            </h4>
          </div>
          <div className="row mt-5">
            <div className="col-md-10 offset-md-1">
              <iframe
                style={{
                  height: "100vh",
                  width: "100%",
                  border: "none",
                  background: "transparent",
                }}
                src="https://forms.zohopublic.in/support152/form/LMS/formperma/lF4TpTVvQi8XTQoeojNXXhveleiJJbFk6HD6iiIBnaA"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
      <section>
        <Footer />
      </section>
    </div>
  );
}

export default Partner;
