import React from "react";
import Navbar from "./Navbar";
import "../Styles/Technology.css";
import drives from "../Assets/technology/drives.png";
import meet from "../Assets/technology/meet.png";
import ashish from "../Assets/technology/ashish.png";
import rohan from "../Assets/technology/rohan.png";
import soumya from "../Assets/technology/soumya.png";
import grafana from "../Assets/technology/icons/grafana.svg";
import esp from "../Assets/technology/icons/esp.svg";
import nginx from "../Assets/technology/icons/nginx.svg";
import googleCloud from "../Assets/technology/icons/googleCloud.svg";
import android from "../Assets/technology/icons/android.svg";
import tensorFlow from "../Assets/technology/icons/tensorFlow.svg";
import texas from "../Assets/technology/icons/texas.svg";
import mqtt from "../Assets/technology/icons/mqtt.svg";
import ios from "../Assets/technology/icons/ios.svg";
import angularjs from "../Assets/technology/icons/angular.svg";
import influx from "../Assets/technology/icons/influx.png";
import monogodb from "../Assets/technology/icons/mongodb.svg";
import redis from "../Assets/technology/icons/redis.svg";
import java from "../Assets/technology/icons/java.svg";
import android1 from "../Assets/technology/icons/android1.svg";
import firebase from "../Assets/technology/icons/firebase.svg";
import python from "../Assets/technology/icons/python.svg";
import nodejs from "../Assets/technology/icons/nodejs.svg";
import c from "../Assets/technology/icons/c.svg";
import reactjs from "../Assets/technology/icons/react.svg";
import elastic from "../Assets/technology/icons/elastic.svg";
import haproxy from "../Assets/technology/icons/haproxy.svg";
import alexa from "../Assets/technology/icons/alexa.svg";
import emq from "../Assets/technology/icons/emq.svg";
import kafka from "../Assets/technology/icons/kafka.svg";
import camp from "../Assets/technology/camp.png";
import chasing from "../Assets/technology/chasing.png";
import system from "../Assets/technology/system.png";
import tinker from "../Assets/technology/tinker.png";
import user from "../Assets/technology/user.png";
import harp from "../Assets/technology/harp.png";
import Footer from "./Footer";

function Technology() {
  return (
    <div className="technology">
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <Navbar />
            </div>
          </div>
        </div>
      </section>
      <section className="technology__section">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h1>We’re a foundry.</h1>
              <h4>
                Entrenched with a deep focus. Illuminated by a bold vision{" "}
              </h4>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-12 mt-md-5">
              <a href="https://ohmassistant.com/blogs" target="_blank">
                <button className="btn mt-md-5">Know more</button>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="technology__building">
        <div className="container">
          <div className="row d-flex justify-content-end align-items-center">
            <div className="col-md-7 mt-5 pt-5">
              <h1 className="mt-md-5 pt-md-5">We’re building for scale </h1>
              <h4>
                Fostering a deep-tech core on an infrastructure that can scale
                to serve homes across the globe
              </h4>

              <div className="row mt-5 pt-5 d-flex justify-content-end align-items-center">
                <div className="col-6 mt-5">
                  <h2>1 billions+</h2>
                  <p>data points processed</p>
                </div>
                <div className="col-6 mt-5">
                  <h2>1000</h2>
                  <p>home experiencing</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="technology__ticking pt-5 pb-5">
        <div className="container pb-5">
          <div className="row">
            <div className="col-md-12">
              <h1 className="text-center">What keeps us ticking </h1>
              <p className="text-center">
                Taking on technology challenges that define the boundaries and
                engineer products that work elegantly.
              </p>
            </div>
          </div>
          <div className="row technology__ticking__sub mt-5">
            <div className="col-md-4 mt-5 offset-md-2 d-flex justify-content-md-start align-items-center justify-content-center">
              <img
                src={camp}
                alt=""
                className="img-fluid"
                style={{ marginRight: "30px" }}
              />
              <p className="mb-0">Championing teamwork</p>
            </div>
            <div className="col-md-4 mt-5 d-flex justify-content-md-end align-items-center justify-content-center">
              <p className="mb-0">Tinkering with possibilities</p>
              <img
                src={tinker}
                alt=""
                className="img-fluid"
                style={{ marginLeft: "30px" }}
              />
            </div>
            <div className="col-md-4 offset-md-2 mt-5 d-flex justify-content-md-start align-items-center justify-content-center">
              <img
                src={chasing}
                alt=""
                className="img-fluid"
                style={{ marginRight: "30px" }}
              />
              <p className="mb-0">Chasing outcomes</p>
            </div>
            <div className="col-md-4  mt-5 d-flex justify-content-md-end align-items-center justify-content-center">
              <p className="mb-0">Systems stack thinking</p>
              <img
                src={system}
                alt=""
                className="img-fluid"
                style={{ marginLeft: "30px" }}
              />
            </div>
            <div className="col-md-4 offset-md-2 mt-5 d-flex justify-content-md-start align-items-center justify-content-center">
              <img
                src={harp}
                alt=""
                className="img-fluid"
                style={{ marginRight: "30px" }}
              />
              <p className="mb-0">Harping on the unsolved</p>
            </div>
            <div className="col-md-4 mt-5 d-flex justify-content-md-end align-items-center justify-content-center">
              <p className="mb-0">Anchored to the user</p>
              <img
                src={user}
                alt=""
                className="img-fluid"
                style={{ marginLeft: "30px" }}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="technology__tech mt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="text-center mt-5">Tech stack </h1>
              <p className="text-center">
                A stack designed to bring the best of emerging technologies
                right into the hands of our users.
              </p>
              <p className="text-center">
                Gets more powerful with every new home that is added to the
                network.
              </p>
            </div>
          </div>
          <div className="row ohm_tec_icon pb-5">
            <div className="col-md-12 mt-5">
              <div className="row d-flex justify-content-center align-items-center">
                <div className="col-md-2 col-4 mt-md-4 mt-2">
                  <img src={grafana} alt="" className="img-fluid" />
                </div>
                <div className="col-md-2 col-4 mt-md-4 mt-2">
                  <img src={tensorFlow} alt="" className="img-fluid" />
                </div>
                <div className="col-md-2 col-4 mt-md-4 mt-2">
                  <img src={influx} alt="" className="img-fluid" />
                </div>
                <div className="col-md-2 col-4 mt-md-4 mt-2">
                  <img src={firebase} alt="" className="img-fluid" />
                </div>
                <div className="col-md-2 col-4 mt-md-4 mt-2">
                  <img src={elastic} alt="" className="img-fluid" />
                </div>
              </div>
              <div className="row d-flex justify-content-center align-items-center">
                <div className="col-md-2 col-4 mt-md-4 mt-2">
                  <img src={esp} alt="" className="img-fluid" />
                </div>
                <div className="col-md-2 col-4 mt-md-4 mt-2">
                  <img src={texas} alt="" className="img-fluid" />
                </div>
                <div className="col-md-2 col-4 mt-md-4 mt-2">
                  <img src={monogodb} alt="" className="img-fluid" />
                </div>
                <div className="col-md-2 col-4 mt-md-4 mt-2">
                  <img src={python} alt="" className="img-fluid" />
                </div>
                <div className="col-md-2 col-4 mt-md-4 mt-2">
                  <img src={haproxy} alt="" className="img-fluid" />
                </div>
              </div>
              <div className="row d-flex justify-content-center align-items-center">
                <div className="col-md-2 col-4 mt-md-4 mt-2">
                  <img src={nginx} alt="" className="img-fluid" />
                </div>
                <div className="col-md-2 col-4 mt-md-4 mt-2">
                  <img src={mqtt} alt="" className="img-fluid" />
                </div>
                <div className="col-md-2 col-4 mt-md-4 mt-2">
                  <img src={redis} alt="" className="img-fluid" />
                </div>
                <div className="col-md-2 col-4 mt-md-4 mt-2">
                  <img src={nodejs} alt="" className="img-fluid" />
                </div>
                <div className="col-md-2 col-4 mt-md-4 mt-2">
                  <img src={alexa} alt="" className="img-fluid" />
                </div>
              </div>
              <div className="row d-flex justify-content-center align-items-center">
                <div className="col-md-2 col-4 mt-md-4 mt-2">
                  <img src={googleCloud} alt="" className="img-fluid" />
                </div>
                <div className="col-md-2 col-4 mt-md-4 mt-2">
                  <img src={ios} alt="" className="img-fluid" />
                </div>
                <div className="col-md-2 col-4 mt-md-4 mt-2">
                  <img src={java} alt="" className="img-fluid" />
                </div>
                <div className="col-md-2 col-4 mt-md-4 mt-2">
                  <img src={c} alt="" className="img-fluid" />
                </div>
                <div className="col-md-2 col-4 mt-md-4 mt-2">
                  <img src={emq} alt="" className="img-fluid" />
                </div>
              </div>
              <div className="row d-flex justify-content-center align-items-center">
                <div className="col-md-2 col-4 mt-md-4 mt-2">
                  <img src={android} alt="" className="img-fluid" />
                </div>
                <div className="col-md-2 col-4 mt-md-4 mt-2">
                  <img src={angularjs} alt="" className="img-fluid" />
                </div>
                <div className="col-md-2 col-4 mt-md-4 mt-2">
                  <img src={android1} alt="" className="img-fluid" />
                </div>
                <div className="col-md-2 col-4 mt-md-4 mt-2">
                  <img src={reactjs} alt="" className="img-fluid" />
                </div>
                <div className="col-md-2 col-4 mt-md-4 mt-2">
                  <img src={kafka} alt="" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="technology__men__behind">
        <div className="container">
          <div className="row mt-5">
            <div className="col-md-12">
              <h2 className="text-center">Men behind the machines.</h2>
            </div>
          </div>
          <div className="row ohm__group pb-md-5 pb-5">
            <div className="col-md-12 mt-5">
              <div className="row d-flex justify-content-center align-items-center">
                <div className="col-md-5">
                  <img
                    src={rohan}
                    alt=""
                    className="img-fluid d-block ml-auto mr-auto mt-5"
                  />
                </div>
                <div className="col-md-4 offset-md-1">
                  <h4 className="font-weight-bold">Rohan Gupta</h4>
                  <p className=" font-weight-bold text-start">
                    Chief Technology Officer
                  </p>
                  <small className="text-md-left d-block">
                    “Deep-tech applications are at the cusp of digital and
                    physical adoption - gradually making them a part of
                    consumers’ daily lives”
                  </small>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row d-flex justify-content-center align-items-center reverse__section">
                <div className="col-md-5 mt-md-0 mt-5">
                  <h4 className="font-weight-bold ">Meet Patel</h4>
                  <p className=" font-weight-bold text-start">
                    Senior Associate, Machine Learning
                  </p>
                  <small className="d-block">
                    “Although NIALM has been a buzz ward for more than a decade,
                    it's still in it's infancy when it comes to both accuracy &
                    real-time monitoring, and that is what we basically do using
                    ML and getting incrementally closer to it”.
                  </small>
                </div>
                <div className="col-md-5">
                  <img
                    src={meet}
                    alt=""
                    className="img-fluid d-block ml-auto mt-5"
                  />
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="row d-flex justify-content-center align-items-center">
                <div className="col-md-5">
                  <img
                    src={soumya}
                    alt=""
                    className="img-fluid d-block ml-auto mr-auto mt-5"
                  />
                </div>
                <div className="col-md-4 mt-5 mt-md-0 offset-md-1">
                  <h4 className="font-weight-bold ">Soumya Bhattacharya</h4>
                  <p className=" font-weight-bold text-start">
                    Chief of Data & CFO
                  </p>
                  <small className=" d-block">
                    “Data security and data hygiene is of paramount importance
                    to us because we deal with huge volumes of sensitive data
                    day in and day out”
                  </small>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row d-flex justify-content-center align-items-center reverse__section">
                <div className="col-md-5 mt-md-0 mt-5">
                  <h4 className="font-weight-bold">Ashish Taldeokar</h4>
                  <p className=" font-weight-bold text-start">
                    Technology Lead
                  </p>
                  <small className="d-block">
                    “Every time I reach a new level of strength, a greater power
                    appears to challenge my authority”.
                  </small>
                </div>
                <div className="col-md-5">
                  <img
                    src={ashish}
                    alt=""
                    className="img-fluid d-block ml-auto mr-md-5 mt-5"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <Footer />
      </section>
    </div>
  );
}

export default Technology;
