import React from "react";
import "../Styles/Footer.css";
import sustlab from "../Assets/footer/sustlabs.svg";
import appStore from "../Assets/footer/appStore.png";
import playStore from "../Assets/footer/playStore.png";
import fb from "../Assets/footer/fb.png";
import yt from "../Assets/footer/yt.png";
import insta from "../Assets/footer/insta.png";
import link from "../Assets/footer/link.png";
import google from "../Assets/footer/google.png";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <section className="footer__section">
      <div className="container-fluid">
        <div className="row mt-5">
          <h2 className="text-center text-light">We’re good at watt we do. </h2>
          <p className="text-center text-light">
            Inspiring energy efficiency for a sustainable future.{" "}
          </p>
          <a
            href="https://shop.ohmassistant.com/collections/all"
            style={{ textDecoration: "none" }}
            target="_blank"
          >
            <button className="btn order__btn mt-md-5 mt-3">Order Now</button>
          </a>
        </div>
        <div className="row footer__link mt-md-5 mt-3">
          <div className="col-md-4  offset-md-4">
            <div className="row">
              <div
                className="col-md-2 text-center col-3"
                style={{ borderRight: "1px solid #555556" }}
              >
                {/* <Link to="/">Home</Link> */}
                <a href="/">Home</a>
              </div>
              <div
                className="col-md-3 text-center col-4"
                style={{ borderRight: "1px solid #555556" }}
              >
                {/* <Link to="/product">Product</Link> */}
                <a href="/product">Product</a>
              </div>
              <div
                className="col-md-3 text-center col-4"
                style={{ borderRight: "1px solid #555556" }}
              >
                {/* <Link to="/technology">Technology</Link> */}
                <a href="/technology">Technology</a>
              </div>
              <div
                className="col-md-3 text-center col-4 mt-md-0 mt-3"
                style={{ borderRight: "1px solid #555556" }}
              >
                <a href="/partner">Partner</a>
              </div>
              <div className="col-md-1  text-center col-4 mt-md-0 mt-3">
                <a href="/about">About</a>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-md-5 mt-5 sub__link">
          <div className="col-md-6 offset-md-3">
            <div className="row">
              <div
                className="col-md-3 col-5 text-center"
                style={{ borderRight: "1px solid #555556" }}
              >
                <a href="/privacy-policy">
                  <a>Privacy policy </a>
                </a>
              </div>
              <div
                className="col-md-3 col-7 text-center"
                style={{ borderRight: "1px solid #555556" }}
              >
                <a href="/term-and-condition">Terms & Conditions</a>
              </div>
              <div
                className="col-md-3 col-6 text-center  mt-md-0 mt-3"
                style={{ borderRight: "1px solid #555556" }}
              >
                <a href="/sales-and-refund">Sales and Refund</a>
              </div>
              <div
                className="col-md-2 col-3 text-center mt-md-0 mt-3"
                style={{ borderRight: "1px solid #555556" }}
              >
                <a href="/contact-us">Get in touch</a>
              </div>
              <div className="col-md-1 col-3 text-center mt-md-0 mt-3">
                <a href="/faq">FAQ’S</a>
              </div>
            </div>
          </div>
        </div>

        <div className="footer__bottom__link">
          {/* <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <img
                src={sustlab}
                alt=""
                className="img-fluid"
                style={{ width: "250px" }}
              />
            </div>
          </div> */}
          <div className="row align-items-end">
            <div className="col-md-4 store__link d-flex justify-content-center align-items-center">
              <a
                href="https://apps.apple.com/in/app/ohm-assistant/id1545565890"
                target="_blank"
              >
                <img src={appStore} alt="" className="img-fluid mr-3" />
              </a>

              <a
                href="https://play.google.com/store/apps/details?id=com.sustlabs.ohmassistant"
                target="_blank"
              >
                <img src={playStore} alt="" className="img-fluid" />
              </a>
            </div>
            <div className="col-md-3 col-12 d-flex justify-content-md-start justify-content-center flex-column align-items-center offset-md-1">
              <img
                src={sustlab}
                alt=""
                className="img-fluid"
                style={{ width: "250px" }}
              />
              
              <img src={google} alt="" className="img-fluid mt-4" />
            </div>

            <div className="col-md-3 social__link d-flex justify-content-center justify-content-md-end align-items-center">
              <a href="https://www.facebook.com/SustLabs/" target="_blank">
                <img src={fb} alt="" className="img-fluid" />
              </a>

              <a href="https://www.instagram.com/ohmassistant/" target="_blank">
                <img src={insta} alt="" className="img-fluid" />
              </a>

              <a
                href="https://www.youtube.com/channel/UC558NUMecY44wdjU_W-t00w"
                target="_blank"
              >
                <img src={yt} alt="" className="img-fluid" />
              </a>

              <a
                href="https://www.linkedin.com/company/sustlabs"
                target="_blank"
              >
                <img src={link} alt="" className="img-fluid" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer;
