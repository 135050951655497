import React from "react";
import Navbar from "./Navbar";
import "../Styles/PrivacyPolicy.css";
import sign from "../Assets/privacyPolicy/Sign.png";
import Footer from "./Footer";

function PrivacyPolicy() {
  return (
    <div className="privacy-policy">
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <Navbar />
            </div>
          </div>
        </div>
      </section>
      <section className="pb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mt-md-5 mt-2">
              <h1 className="text-center mt-5">Privacy policy</h1>
            </div>
            <div className="col-md-10 offset-md-1">
              <h5 className="mt-3 text-center">
                The terms "We" / "Us" / "Our"/”Company” individually and
                collectively refer to SustLabs, Ohm Assistant and App-in-app and
                the terms "You" /"Your" / "Yourself" refer to the users.
              </h5>
            </div>
            <div className="col-md-10 offset-md-1 mt-5">
              <p>
                This Privacy Policy is an electronic record in the form of an
                electronic contract formed under the information Technology Act,
                2000 and the rules made thereunder and the amended provisions
                pertaining to electronic documents / records in various statutes
                as amended by the information Technology Act, 2000. This Privacy
                Policy does not require any physical, electronic or digital
                signature.
              </p>
              <p>
                This Privacy Policy is a legally binding document between you
                and SustLabs (both terms defined below). The terms of this
                Privacy Policy will be effective upon your acceptance of the
                same (directly or indirectly in electronic form, by clicking on
                the I accept tab or by use of the website or by other means) and
                will govern the relationship between you and the Company for
                your use of the website “Website” (defined below).
              </p>
              <p>
                This document is published and shall be construed in accordance
                with the provisions of the Information Technology (reasonable
                security practices and procedures and sensitive personal data of
                information) rules, 2011 under Information Technology Act, 2000;
                that require publishing of the Privacy Policy for collection,
                use, storage and transfer of sensitive personal data or
                information.
              </p>
              <p>
                Please read this Privacy Policy carefully by using the Website,
                you indicate that you understand, agree and consent to this
                Privacy Policy. If you do not agree with the terms of this
                Privacy Policy, please do not use this Website.
              </p>
              <p>
                By providing us your Information or by making use of the
                facilities provided by the Website, You hereby consent to the
                collection, storage, processing and transfer of any or all of
                Your Personal Information and Non-Personal Information by us as
                specified under this Privacy Policy. You further agree that such
                collection, use, storage and transfer of Your Information shall
                not cause any loss or wrongful gain to you or any other person.
              </p>
            </div>

            <div className="col-md-10 offset-md-1 b__gray mt-5">
              <h4 className="mt-2">User information </h4>
            </div>
            <div className="col-md-10 offset-md-1 mt-5">
              <p>
                To avail certain services on our Websites, users are required to
                provide certain information for the registration process namely:
                -
              </p>
              <p>
                (a) your name <br></br> (b) email address <br></br> (c) sex{" "}
                <br></br> (d) age <br></br>(e) PIN code, etc., and / or your
                occupation, interests, and the like. The Information as supplied
                by the users enables us to improve our sites and provide you the
                most user-friendly experience.
              </p>
              <p>
                All required information is service dependent and we may use the
                above said user information to, maintain, protect, and improve
                its services (including advertising services) and for developing
                new services.
              </p>
              <p>
                Such information will not be considered as sensitive if it is
                freely available and accessible in the public domain or is
                furnished under the Right to Information Act, 2005 or any other
                law for the time being in force.
              </p>
            </div>
            <div className="col-md-10 offset-md-1 b__gray mt-5">
              <h4 className=" mt-2">Cookies </h4>
            </div>
            <div className="col-md-10 offset-md-1 mt-5">
              <p>
                To improve the responsiveness of the sites for our users, we may
                use "cookies", or similar electronic tools to collect
                information to assign each visitor a unique, random number as a
                User Identification (User ID) to understand the user's
                individual interests using the Identified Computer. Unless you
                voluntarily identify yourself (through registration, for
                example), we will have no way of knowing who you are, even if we
                assign a cookie to your computer. The only personal information
                a cookie can contain is information you supply (an example of
                this is when you ask for our Personalised Horoscope). A cookie
                cannot read data off your hard drive. Our advertisers may also
                assign their own cookies to your browser (if you click on their
                ads), a process that we do not control.
              </p>
              <p>
                Our web servers automatically collect limited information about
                your computer's connection to the Internet, including your IP
                address, when you visit our site. (Your IP address is a number
                that lets computers attached to the Internet know where to send
                you data -- such as the web pages you view.) Your IP address
                does not identify you personally. We use this information to
                deliver our web pages to you upon request, to tailor our site to
                the interests of our users, to measure traffic within our site
                and let advertisers know the geographic locations from where our
                visitors come.
              </p>
            </div>
            <div className="col-md-10 offset-md-1 b__gray mt-5">
              <h4 className=" mt-2">Link to other sites </h4>
            </div>
            <div className="col-md-10 offset-md-1 mt-5">
              <p>
                Our policy discloses the privacy practices for our own web site
                only. Our site provides links to other websites also that are
                beyond our control. We shall in no way be responsible in way for
                your use of such sites. We shares the sensitive personal
                information to any third party without obtaining the prior
                consent of the user in the following limited circumstances:
              </p>
              <p>
                (A) When it is requested or required by law or by any court or
                governmental agency or authority to disclose, for the purpose of
                verification of identity, or for the prevention, detection,
                investigation including cyber incidents, or for prosecution and
                punishment of offences. These disclosures are made in good faith
                and belief that such disclosure is reasonably necessary for
                enforcing these Terms; for complying with the applicable laws
                and regulations.
              </p>
              <p>
                (B) We propose to share such information within its group
                companies and officers and employees of such group companies for
                the purpose of processing personal information on its behalf. We
                also ensure that these recipients of such information agree to
                process such information based on our instructions and in
                compliance with this Privacy Policy and any other appropriate
                confidentiality and security measures.
              </p>
            </div>
            <div className="col-md-10 offset-md-1 b__gray mt-5">
              <h4 className=" mt-2">Information security </h4>
            </div>
            <div className="col-md-10 offset-md-1 mt-5">
              <p>
                We take appropriate security measures to protect against
                unauthorized access to or unauthorized alteration, disclosure or
                destruction of data. These include internal reviews of our data
                collection, storage and processing practices and security
                measures, including appropriate encryption and physical security
                measures to guard against unauthorized access to systems where
                we store personal data.
              </p>
              <p>
                All information gathered on our Website is securely stored
                within our controlled database. The database is stored on
                servers secured behind a firewall; access to the servers is
                password-protected and is strictly limited. However, as
                effective as our security measures are, no security system is
                impenetrable. We cannot guarantee the security of our database,
                nor can we guarantee that information you supply will not be
                intercepted while being transmitted to us over the Internet.
                And, of course, any information you include in a posting to the
                discussion areas is available to anyone with Internet access.
              </p>
              <p>
                However the internet is an ever evolving medium. We may change
                our Privacy Policy from time to time to incorporate necessary
                future changes. Of course, our use of any information we gather
                will always be consistent with the policy under which the
                information was collected, regardless of what the new policy may
                be.
              </p>
            </div>
            <div className="col-md-10 offset-md-1 mt-5">
              <h4 className="mt-2">
                Thought leadership in privacy: Simplified for users{" "}
              </h4>
            </div>
            <div className="col-md-10 offset-md-1 mt-5">
              <p>
                SustLabs understands the importance of data protection & privacy
                and we as a company are trying to establish industry standards
                which are necessary to develop long-term sustainable
                relationships with Ohm users and partners.
              </p>
              <p>
                We would like to start our engagement with users on a note where
                they shall not be subscribed to any services while on-boarding
                and the default state shall be UNSUBSCRIBED from all features
                available (including Vyas). You shall always have the option to
                opt-in (or opt-out) for various services, value-added features
                and much more. We wish to have informed users who understand
                SustLabs' stand on 'right-to-forget'.
              </p>
              <p>
                SustLabs, by design, does not share data with any partner
                companies (through other products). With user consent and only
                upon activation of services, information curated out of the data
                shall be provided to the 3rd parties (such as utilities,
                appliance manufacturing & insurance companies, etc.) but at a
                later stage and only through SustLabs’ portal. You shall be
                notified about the new products as-and-when they are launched
                but users shall always remain un-subscribed unless you opt-in.
              </p>
            </div>

            <div className="col-md-10 offset-md-1 mt-5">
              <h4 className="">What kind information is collected and why?</h4>
            </div>
            <div className="col-md-10 offset-md-1 mt-5">
              <ul>
                <li>
                  To provide the SustLabs Products, we must analyse overall
                  electricity consumption & process electrical-appliances’ level
                  information. The type of information that is collected depends
                  on the electricity usage at the household level. SustLabs
                  collects information when members of the household or related
                  users initiate or access SustLabs’ services like your contact
                  information, such as full name, mobile number and email
                  address; your username and password to provide you with
                  platform access; your appliances’ information and other
                  information related to your home. This can include information
                  in or about the appliances that you provide (e.g. metadata),
                  such as the type of appliance, date of purchase or warranty
                  card; electricity-utility billing information; and information
                  that you provide when you contact or engage platform support
                  regarding the Service.
                </li>
                <li>
                  We use the information that we have to conduct and support
                  research and innovation on topics of energy efficiency,
                  appliance maintenance and longevity, etc. to promote energy
                  lean lifestyle.
                </li>
                <li>
                  We also use user-information to respond to users when they
                  contact us
                </li>
              </ul>
            </div>

            <div className="col-md-10 offset-md-1 mt-5">
              <h4 className="">How do we ensure minimum data is stored?</h4>
            </div>
            <div className="col-md-10 offset-md-1 mt-5">
              <ul>
                <li>
                  SustLabs does not seek any personal information beyond contact
                  and electrical appliances’ details which is only relevant for
                  analysis and training the bot (Ohm).
                </li>
                <li>
                  We also do not assume that every input information provided by
                  the user is accurate or authentic, hence strict system
                  generated screening processes are being developed. .
                </li>
                <li>
                  We highly recommend users to change their system generated
                  password (and keep their password confidential). There are no
                  limits to the number of user access to the same house-data.
                </li>
                <li>
                  To mitigate risk that inevitably comes as the amount of user
                  data increases, strategic deletion is done. User information
                  has a lifespan & we consistently purge stale data from servers
                  to ensure the information accessed is limited to valuable data
                  and does not pose a security threat.
                </li>
              </ul>
            </div>

            <div className="col-md-10 offset-md-1 mt-5">
              <h4 className="">How processes are created?</h4>
            </div>
            <div className="col-md-10 offset-md-1 mt-5">
              <p>
                Proactively introducing features such as ‘Right to Forget’ and
                adopting many other industry best practices instead of reacting
                to the change in data regulatory standards.
              </p>
              <p>
                Privacy as a default is mentioned earlier in the document where
                the default user state is un-subscribed of all the features and
                additional services.
              </p>
            </div>

            <div className="col-md-10 offset-md-1 mt-5">
              <h4 className="">Privacy by design</h4>
            </div>
            <div className="col-md-10 offset-md-1 mt-5">
              <ul>
                <li>
                  The technology development block (isolated from the user data
                  warehouse), which is core to our product, works on training
                  data-sets as a part of an assisted learning model where users
                  can label the appliances which otherwise the bot is able to
                  detect but not identify. Algorithms would process the
                  real-time consumption data which is seen in the appliance (and
                  over-all) data warehousing.
                </li>
                <li>
                  The operations block (contains information against encrypted
                  user details), shall contain real-time information flowing
                  through them but through best in-class infrastructure (offered
                  by Oracle). Access to this information would be available only
                  to the process owners such as Chief of Data (or Chief
                  Executive Officer).
                </li>
                <li>
                  The user data block (isolated block with unidirectional
                  information flow for user mapping) contains information
                  collected from members of the household or related users when
                  they initiate or access SustLabs’ services like contact
                  information, such as full name, mobile number and email
                  address; your username and password to provide you with
                  platform access; appliances’ information and other information
                  related to user home. This can include information about the
                  appliances provided by the user (e.g. metadata), such as the
                  type of appliance, date of purchase or warranty card;
                  electricity-utility; and other information that users provide
                  when they contact or engage platform support regarding the
                  services. This block can only be accessed by the Chief of Data
                  (or Executive) Officer.
                </li>
              </ul>
            </div>

            <div className="col-md-10 offset-md-1 mt-5">
              <h4 className="">Privacy & security together</h4>
            </div>
            <div className="col-md-10 offset-md-1 mt-5">
              <p>
                We do not compromise one for another. While we understand
                nothing is unhackable, we have created a system that would be a
                challenge to breakdown. SustLabs relies on industry leaders for
                privacy and security features layers.
              </p>
              <p>
                End-to-end security: Data is protected throughout its lifecycle.
                We have used state-of-the-art facilities of GCP and other cloud
                service providers.
              </p>
            </div>

            <div className="col-md-10 offset-md-1 mt-5">
              <h4 className="">
                How long do we store the data and in what manner is it stored?
              </h4>
            </div>
            <div className="col-md-10 offset-md-1 mt-5">
              <p>
                Operations level data is purged in frequent intervals and only
                derived (appliance level) information is stored which is part of
                the learning. We shall also frequently check for stale/
                redundant data.
              </p>
              <p>
                User-information data block holds information for longer
                duration in the form of contact information, meta-data, etc. The
                information stored in this block is essential for user mapping..
              </p>
            </div>

            <div className="col-md-10 offset-md-1 mt-5">
              <h4 className="">
                How do we ensure that the data remains de-identified?
              </h4>
            </div>
            <div className="col-md-10 offset-md-1 mt-5">
              <p>
                Operations block will process the encrypted user information.
                The real-time engine and the bot analyse anonymous data only and
                shall always be delinked with user-data server.
              </p>
            </div>

            <div className="col-md-10 offset-md-1 mt-5">
              <h4 className="">
                How do we test for vulnerabilities in our infrastructure{" "}
                <br></br>
                (Security & safeguarding processes)?
              </h4>
            </div>
            <div className="col-md-10 offset-md-1 mt-5">
              <p>
                Operations block will process the encrypted user information.
                The real-time engine and the bot analyse anonymous data only and
                shall always be delinked with user-data server.
              </p>
              <ul>
                <li>
                  Establishing proper workflows may be an outcome of multiple
                  trials and errors but at SustLabs, we are taking this approach
                  with dedicated process owners
                </li>
                <li>
                  We have created a responsibility matrix. As described in the
                  block diagram above, access defines the security layers and
                  responsibilities which allows focused assessments of
                  processes.
                </li>
                <li>
                  Documenting processes and procedures allows us to create
                  process flow maps. This helps us understand the current
                  deficiencies and defects.
                </li>
                <li>
                  Assembling tools that are working for SustLabs. We have been
                  able to equip processes owners with the required set of tools
                  and are in the processes of strengthening it further.
                </li>
                <li>
                  Incident response plan* is being created as a proactive
                  exercise instead of reacting to actual system failures.
                </li>
              </ul>
            </div>
            <div className="col-md-10 offset-md-1 mt-5">
              <h4 className="">Grievance Redressal</h4>
            </div>

            <div className="col-md-10 offset-md-1 mt-5">
              <p>
                Redressal Mechanism: Any complaints, abuse or concerns with
                regards to content and or comment or breach of these terms shall
                be immediately informed to the designated Grievance Officer as
                mentioned below via in writing or through email signed with the
                electronic signature to Kaushik Bose ("Grievance Officer").
              </p>
            </div>

            <div className="col-md-6 offset-md-1 mt-5">
              <p className="mb-0">Mr. Kaushik Bose (Grievance Officer)</p>
              <p className="mb-0">
                <a href="https://www.sustlabs.com/">
                  https://www.sustlabs.com/
                </a>
              </p>
              <p>
                SustLabs, CM-10, SINE, CSRE Building, IIT Bombay, Powai, Mumbai
                -400076
              </p>
              <p className="mb-0 mt-5">Email: kb@sustlabs.com</p>
              <p>Ph.: +91-7738880011</p>
            </div>
            <div className="col-md-4 d-flex justify-content-center align-items-center">
              <img src={sign} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </section>
      <section>
        <Footer />
      </section>
    </div>
  );
}

export default PrivacyPolicy;
