import React from "react";

import faqJson from "../Assets/faq.json";
import Navbar from "./Navbar";
import "../Styles/Faq.css";
import Footer from "./Footer";

function Faq() {
  var ohmBotCategory = [];
  var machineLearningCategory = [];
  var bigDataCategory = [];
  var energySavingCategory = [];
  var issuesCategory = [];
  var generalCategory = [];

  faqJson.forEach((element) => {
    if (element.Category == "Ohm Bot") {
      ohmBotCategory.push(element);
    }
    if (element.Category == "General") {
      generalCategory.push(element);
    }
    if (element.Category == "Machine Learning") {
      machineLearningCategory.push(element);
    }
    if (element.Category == "Big Data") {
      bigDataCategory.push(element);
    }
    if (element.Category == "Energy saving") {
      energySavingCategory.push(element);
    }
    if (element.Category == "Issues & Limitations") {
      issuesCategory.push(element);
    }
  });
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <Navbar />
        </div>
      </div>
      <div className="row faq__questions">
     
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1 mt-5">
              <h3 className="font-weight-bold">Find my answers </h3>
            </div>
            <div className="col-md-10 offset-md-1 mt-5">
              <h4>Ohm Bot</h4>
              <div className="accordion" id="ohmBotAccParent">
              {ohmBotCategory.map(function (element) {
                return (
                 
                    <div className="card" id={element.index}>
                      <div
                        className="card-head"
                        id={`heading_${element.index}`}
                      >
                        <p
                          className="mb-0 font-weight-bolder"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapse_${element.index}`}
                          aria-expanded="true"
                          aria-controls={`collapse_${element.index}`}
                        >
                          {element.Question}
                        </p>
                      </div>

                      <div
                        id={`collapse_${element.index}`}
                        className="collapse"
                        aria-labelledby={`heading_${element.index}`}
                        data-bs-parent="ohmBotAccParent"
                      >
                        <div className="card-body">
                          <small>{element.Answer}</small>
                        </div>
                      </div>
                    </div>
                 
                );
              })}
               </div>
             
              <h4 className="mt-5">Machine Learning </h4>
              <div className="accordion" id="mlAccParent">
              {machineLearningCategory.map(function (element) {
                return (
                 
                    <div className="card" id={element.index}>
                      <div
                        className="card-head"
                        id={`heading_${element.index}`}
                      >
                        <p
                          className="mb-0 font-weight-bolder"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapse_${element.index}`}
                          aria-expanded="true"
                          aria-controls={`collapse_${element.index}`}
                        >
                          {element.Question}
                        </p>
                      </div>

                      <div
                        id={`collapse_${element.index}`}
                        className="collapse"
                        aria-labelledby={`heading_${element.index}`}
                        data-bs-parent="mlAccParent"
                      >
                        <div className="card-body">
                          <small>{element.Answer}</small>
                        </div>
                      </div>
                    </div>
                 
                );
              })}
               </div>
              <h4 className="mt-5">Big Data</h4>
              <div className="accordion" id="BigDataAccParent">
              {bigDataCategory.map(function (element) {
                return (
                 
                    <div className="card" id={element.index}>
                      <div
                        className="card-head"
                        id={`heading_${element.index}`}
                      >
                        <p
                          className="mb-0 font-weight-bolder"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapse_${element.index}`}
                          aria-expanded="true"
                          aria-controls={`collapse_${element.index}`}
                        >
                          {element.Question}
                        </p>
                      </div>

                      <div
                        id={`collapse_${element.index}`}
                        className="collapse"
                        aria-labelledby={`heading_${element.index}`}
                        data-bs-parent="BigDataAccParent"
                      >
                        <div className="card-body">
                          <small>{element.Answer}</small>
                        </div>
                      </div>
                    </div>
                 
                );
              })}
               </div>
              <h4 className="mt-5">Energy saving </h4>
              <div className="accordion" id="EnergySavingAccParent">
              {energySavingCategory.map(function (element) {
                return (
                 
                    <div className="card" id={element.index}>
                      <div
                        className="card-head"
                        id={`heading_${element.index}`}
                      >
                        <p
                          className="mb-0 font-weight-bolder"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapse_${element.index}`}
                          aria-expanded="true"
                          aria-controls={`collapse_${element.index}`}
                        >
                          {element.Question}
                        </p>
                      </div>

                      <div
                        id={`collapse_${element.index}`}
                        className="collapse"
                        aria-labelledby={`heading_${element.index}`}
                        data-bs-parent="EnergySavingAccParent"
                      >
                        <div className="card-body">
                          <small>{element.Answer}</small>
                        </div>
                      </div>
                    </div>
                 
                );
              })}
               </div>
              <h4 className="mt-5">Issues & Limitations </h4>
              <div className="accordion" id="issuesAccParent">
              {issuesCategory.map(function (element) {
                return (
                 
                    <div className="card" id={element.index}>
                      <div
                        className="card-head"
                        id={`heading_${element.index}`}
                      >
                        <p
                          className="mb-0 font-weight-bolder"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapse_${element.index}`}
                          aria-expanded="true"
                          aria-controls={`collapse_${element.index}`}
                        >
                          {element.Question}
                        </p>
                      </div>

                      <div
                        id={`collapse_${element.index}`}
                        className="collapse"
                        aria-labelledby={`heading_${element.index}`}
                        data-bs-parent="issuesAccParent"
                      >
                        <div className="card-body">
                          <small>{element.Answer}</small>
                        </div>
                      </div>
                    </div>
                 
                );
              })}
               </div>
              <h4 className="mt-5">General </h4>
              <div className="accordion" id="generalAccParent">
              {generalCategory.map(function (element) {
                return (
                 
                    <div className="card" id={element.index}>
                      <div
                        className="card-head"
                        id={`heading_${element.index}`}
                      >
                        <p
                          className="mb-0 font-weight-bolder"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapse_${element.index}`}
                          aria-expanded="true"
                          aria-controls={`collapse_${element.index}`}
                        >
                          {element.Question}
                        </p>
                      </div>

                      <div
                        id={`collapse_${element.index}`}
                        className="collapse"
                        aria-labelledby={`heading_${element.index}`}
                        data-bs-parent="generalAccParent"
                      >
                        <div className="card-body">
                          <small>{element.Answer}</small>
                        </div>
                      </div>
                    </div>
                 
                );
              })}
               </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 p-0">
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Faq;
