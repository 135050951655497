import React, {useEffect} from "react";

function Salesforce() {
  const myFunction = () => {
    var x = document.getElementById("last_name").value;
    console.log(x);
    // document.getElementById("company").innerHTML = x;
  };

  // var getUrlParameter = function getUrlParameter(sParam) {
  //   var sPageURL = decodeURIComponent(window.location.search.substring(1)),
  //     sURLVariables = sPageURL.split("&"),
  //     sParameterName,
  //     i;
  //   for (i = 0; i < sURLVariables.length; i++) {
  //     sParameterName = sURLVariables[i].split("=");
  //     if (sParameterName[0] === sParam) {
  //       return sParameterName[1] === undefined ? true : sParameterName[1];
  //     }
  //   }
  // };

  var customURL = window.location.search;
  var params = new URLSearchParams(customURL.split("?")[1]);

  var utmsource = params.get("utm_source");
  var utmmedium = params.get("utm_medium");
  var utmcontent = params.get("utm_content");
  var utmcampaign = params.get("utm_campaign");
  var referrer = params.get("referrername");
  var source = params.get("source");

  // if (utmsource != null) {
  //   document.getElementById("source").value = utmsource;
  // }
  // if (utmmedium != null) {
  //   document.getElementById("medium").value = utmmedium;
  // }
  // if (utmcontent != null) {
  //   document.getElementById("content").value = utmcontent;
  // }
  // if (utmcampaign != null) {
  //   document.getElementById("campaign").value = utmcampaign;
  // }
  // if (referrer != null) {
  //   document.getElementById("referrer").value = referrer;
  // }
  // if (source != null) {
  //   document.getElementById("lead_source").value = source;
  // }
  

  return (
    <div>
      <form
        action="https://test.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
        method="POST"
      >
        <input type="hidden" name="oid" value="00D0w0000008hua" />
        <input type="hidden" name="retURL" value="http://sustlabs.com" />
        <label for="first_name">First Name</label> <br></br>
        <input
          id="first_name"
          maxlength="40"
          name="first_name"
          size="20"
          type="text"
          required
        />
        <br></br>
        <label for="last_name">Last Name</label>
        <br></br>
        <input
          id="last_name"
          maxlength="80"
          name="last_name"
          size="20"
          type="text"
          onKeyUp={() => myFunction()}
          required
        />
        <br></br>
        <label for="email">Email</label>
        <br></br>
        <input
          id="email"
          maxlength="80"
          name="email"
          size="20"
          type="text"
          required
        />
        <br></br>
        <label for="phone">Phone</label>
        <br></br>
        <input
          id="phone"
          maxlength="40"
          name="phone"
          size="20"
          type="text"
          required
        />
        <br></br>
        Purpose:<br></br>
        <select
          id="00NN000000CuPoH"
          name="00N0w000005zQE2"
          title="Purpose"
          required
        >
          <option value="">--None--</option>
          <option value="Home">Home</option>
          <option value="Business">Business</option>
        </select>
        <br></br>
        Average Monthly Electricity Bill:<br></br>
        <input
          id="00NN000000CuPsU"
          name="00N0w000005zQER"
          size="20"
          type="text"
        />
        <br></br>
        Zip Code: <br></br>
        <input
          id="00N0w0000061E2c"
          maxlength="50"
          name="00N0w0000061E2c"
          size="20"
          type="text"
        />
        <br></br>
        <label for="lead_source"></label>
        <select id="lead_source" name="lead_source" hidden>
          <option value="Website">Website</option>
          <option value="Facebook">Facebook</option>
          <option value="Referrer">Referrer</option>
          <option value="Distributor">Distributor</option>
          <option value="Instagram">Instagram</option>
          <option value="Other">Other</option>
          <option value="What&#39;s App">What&#39;s App</option>
        </select>
        <label for="rating"></label>
        <select id="rating" name="rating" hidden>
          <option value="Warm">Warm</option>
          <option value="Hot">Hot</option>
          <option value="Cold">Cold</option>
        </select>
        <input
          id="source"
          maxlength="100"
          name="00N0w000005zQEb"
          size="20"
          type="text"
          hidden
          value={params.get("utm_source")}
        />
        <input
          id="medium"
          maxlength="100"
          name="00N0w000005zQEg"
          size="20"
          type="text"
          hidden
          value={params.get("utm_medium")}
        />
        <input
          id="content"
          maxlength="100"
          name="00N0w000005zQEl"
          size="20"
          type="text"
          hidden
          value={params.get("utm_content")}
        />
        <input
          id="campaign"
          maxlength="100"
          name="00N0w000005zQEq"
          size="20"
          type="text"
          hidden
          value={params.get("utm_campaign")}
        />
        <input
          id="referrer"
          maxlength="100"
          name="00N0w000005zQEv"
          size="20"
          type="text"
          value={params.get("referrername")}
          hidden
        />
        <input type="submit" name="submit" />
      </form>
    </div>
  );
}

export default Salesforce;
