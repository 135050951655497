import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Link } from "react-router-dom";
import Routes from "./Routes/Routes";
import CookieConsent from "react-cookie-consent";
// import Routes

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
      {/* <CookieConsent>
        This site uses cookies. See our{" "}
        <a href="/privacy-policy">privacy policy</a> for more.
      </CookieConsent> */}
      <CookieConsent
        // overlay={true}
        location="bottom"
        buttonText="I Understand"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B", fontSize: "14px" }}
        buttonStyle={{ color: "#fff", fontSize: "14px", background: "#277196" }}
        expires={150}
      >
        "By continuing your browsing on this website, you allow us the use of
        cookies in order to optimize the functionality of the &nbsp;
        <span>
          <a
            href="sustlabs.com"
            style={{ color: "#277196", fontWeight: "bolder" }}
          >
            sustlabs.com
          </a>
        </span>
        &nbsp; website through the production of anonymous and statistical
        data."
      </CookieConsent>
    </div>
  );
}

export default App;
