import React from "react";
import Navbar from "./Navbar";
import "../Styles/SalesandRefund.css";
import Footer from "./Footer";

function SalesandRefund() {
  return (
    <div className="sales-refund">
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <Navbar />
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-12 mt-md-5 mt-2">
              <h1 className="text-center mt-5">Sales & Refund policy </h1>
            </div>
            <div className="col-md-10 offset-md-1 b__gray mt-5">
              <h4 className="ml-md-5 mt-2">Cancellation before shipment </h4>
            </div>
            <div className="col-md-10 offset-md-1 mt-5">
              <p>
                If the order or the item(s) that you want to cancel have not
                been shipped yet, you can write to our customer support team on
                support@sustlabs.com or call us on 022-20850360 / +91 773 888
                0011. (During Business days between 10am to 7pm).
              </p>
              <p>
                In such cases, the order will be cancelled, and we will initiate
                a refund within 4-5 business days after the cancellation request
                is duly processed by us.
              </p>
            </div>

            <div className="col-md-10 offset-md-1 b__gray mt-5">
              <h4 className="ml-md-5 mt-2">
                Returns, Replacements and Refunds
              </h4>
            </div>
            <div className="col-md-10 offset-md-1 mt-5">
              <p>
                Returns is a scheme provided under the policy in terms of which
                the option of exchange, replacement and/ or refund is offered by
                SustLabs to you. For all products, the returns/replacement
                policy provided on the product shall prevail over the general
                returns policy. Do refer to the terms and conditions for the
                return/replacement policy for Ohm Assistant product.
              </p>
              <p>
                SustLabs offers its customers an "Easy return policy", wherein
                you can raise a return/exchange request of a product within 10
                days of its delivery.
              </p>
              <p>
                <strong>Step 1</strong> <br></br>
                Contact our Customer Support team via email
                (support@sustlabs.com) within 10 business days of receiving the
                order.
              </p>
              <p>
                <strong>Step 2</strong> <br></br>
                Provide us with your order ID details and your request to
                return/replace/refund your order. Kindly email an image of the
                product and the invoice for our reference.
              </p>
              <p>
                <strong>Step 3</strong> <br></br>
                We will pick up the products within 3-4 business days. We will
                initiate the refund or replacement process only if the products
                are received by us in their original packaging with their seals,
                labels and barcodes intact
              </p>
              <p>
                The return policy is divided into three parts; Do read all
                sections carefully to understand the conditions and cases under
                which returns will be accepted.
              </p>
            </div>

            <div className="col-md-10 offset-md-1 mt-5">
              <h4>Part 1 – Category, Return Window and Actions possible</h4>
              <p className="mt-3">
                1_Ohm / 3_Ohm (Ohm_Home): 10 days Replacement only
              </p>
              <p>
                In order to help you resolve issues with your product, we may
                troubleshoot your product either through online tools, over the
                phone, and/or through an in-person technical visit.
              </p>
              <p>
                If a defect is determined within the Returns Window, a
                replacement of the same model will be provided at no additional
                cost. If no defect is confirmed or the issue is not diagnosed
                within 10 days of delivery, the product will be replaced.
              </p>
            </div>

            <div className="col-md-10 offset-md-1 mt-5">
              <h4>Part 2 - Returns Pick-Up and Processing</h4>
              <p className="mt-5">
                In case of returns where you would like item(s) to be picked up
                from a different address, the address can only be changed if
                pick-up service is available at the new address
              </p>
              <p>
                During pick-up or after receiving at the company’s office, your
                product will be checked for the following conditions:
              </p>
            </div>

            <div className="col-md-10 offset-md-1 b__gray mt-5">
              <h4 className="ml-md-5 mt-2">Category and Conditions</h4>
            </div>
            <div className="col-md-10 offset-md-1 mt-5">
              <p>
                <strong>Correct Product:</strong> IMEI/ name/ image/ brand/
                serial number/ article number/ bar code should match and MRP tag
                should be undetached and clearly visible.
              </p>
              <p>
                <strong>Complete Product:</strong> All in-the-box accessories
                (like user manuals, cables etc.) should be present.
              </p>
              <p>
                <strong> Unused Product:</strong> The product should be unused
                with non-tampered quality check seals/return tags/warranty seals
                (wherever applicable). Before returning an Ohm Assistant
                Product, the device should be packed well as it appeared.
              </p>
              <p>
                <strong>Undamaged Product:</strong> The product should be
                undamaged and without any scratches, dents, tears or holes.
              </p>
              <p>
                <strong>Undamaged Packaging: </strong> Product's original
                packaging/ box should be undamaged.
              </p>
              <h4 className="mt-5">
                Part 3 - General Rules for a successful Return
              </h4>
              <p className="mt-5" style={{ fontWeight: "bold" }}>
                How will I get refunded for the cancelled orders and how long
                will this process take?
              </p>
              <p>
                In case of cancellation before shipment, we process the refund
                within 4-5 business days after receiving the cancellation
                request.
              </p>
              <p>
                In case of cancellation once the shipment has already been
                dispatched or if it is being returned, we process the refund
                once the products have been received and verified at our
                warehouse
              </p>
              <p>
                For payments done through credit/debit cards or net banking, the
                refund will be processed to the same account from which the
                payment was made within 4-6 business days of us receiving the
                products back. It may take 2-3 additional business days for the
                amount to reflect in your account.
              </p>
              <p>
                If a product received as damaged or any conditions not satisfied
                as mentioned in Part 2, then no refund shall be applicable
              </p>
              <h4 className="mt-5">
                What if I used discount vouchers during time of payment and I
                have to cancel my order?
              </h4>
              <p className="mt-5">
                Discount vouchers are intended for one-time use only and shall
                be treated as used even if you cancel the order
              </p>
              <h4 className="mt-5">
                I have received a damaged or defective item/wrong product in my
                order, how should I proceed?
              </h4>
              <p className="mt-5">
                Our shipments go through rigorous quality check processes before
                they leave our warehouse. However, in the rare case that your
                product is damaged during shipment or transit, you can request
                for a replacement or cancellation and refund
              </p>
              <p>
                In case the product was not delivered and you received a
                delivery confirmation email/SMS, report the issue within 5 days
                from the date of delivery confirmation for the seller to
                investigate
              </p>
              <p>
                If you have received an item in a damaged/defective condition or
                have been sent a wrong product, you can follow a few simple
                steps to initiate your return/refund within 5 days of receiving
                the order
              </p>
              <p>
                <strong>Step 1: </strong> Contact our Customer Support team via
                email (support@sustlabs.com) within 5 business days of receiving
                the order.
              </p>
              <p>
                <strong>Step 2: </strong> Provide us with your order ID details
                and your request to return/replace/refund the defective/wrong
                items in your order. Kindly share an image of the product and
                the invoice for our reference
              </p>
              <p>
                <strong>Step 13: </strong> We will pick up the products within
                3-4 business days. We will initiate the refund or replacement
                process only if the products are received by us in their
                original packaging with their seals, labels and barcodes intact.
              </p>
              <p style={{ fontWeight: "bold" }}>
                <i>
                  Note :If it is a case of replacement, it is subject to the
                  availability of stock. In case that a replacement may not be
                  available, we will refund you the full amount.
                </i>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <Footer />
      </section>
    </div>
  );
}

export default SalesandRefund;
